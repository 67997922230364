import moment from "moment";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import GameResult from "./js/GameResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";

const momenttz = require('moment-timezone')

const App = () => {
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display


  // Get current date and time
  const currentDateDisplay = moment().format("lll");
  const currentDate = moment().format("YYYY-MM-DD");
  const prevDate = moment().subtract(1, 'days').format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");

  // Debugging log to check current time

  // useEffect(() => {
  //   trackVisitor(); // Call the tracking function when the component mounts
  // }, []);



  useEffect(() => {
    fetch('https://api.sattakingvip.co.in/getData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open"
      }),
    })
      .then(response => response.json())
      .then(json => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, 'HH:mm');
          const timeB = moment(b.open_time, 'HH:mm');
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);


      })
      .catch(error => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, 'HH:mm');

      // Process and filter the data
      const processedData = data.map(item => {
        const itemTime = moment(item.open_time, 'HH:mm');
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : 'wait',
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(item => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter(item => !item.isAvailable);

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(availableResults[availableResults.length - 1]);
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log

    }
  }, [data, currentTime]);



  function pageScroll() {
    const daySection = document.getElementById("dayResult");
    const tableSection = document.getElementById("monthTable");
    if (daySection) {
      daySection.scrollIntoView({ behavior: "smooth" });
    }
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }


  // Navigation handler
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
  };


  return (
    <div className="col-12">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta name="description" content="sattasport, satta sport, sattaking" />
        <meta name="Keywords"
          content="sking, s king s-king, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king," />
        <link rel="canonical" href="https://babamatka.com/" />
      </Helmet>
      {/* seo setup end */}
      <div className="menu">
        <div className="row text-center">
          <div className='col-md-4 col-sm-12'><button className='btn btn-light mt-2 form-control col-md-4 col-sm-12' onClick={() => handleNavigation('/')}>HOME</button></div>
          <div className='col-md-4 col-sm-12'><button className='btn btn-light mt-2 form-control col-md-4 col-sm-12' onClick={(e) => {
            e.preventDefault();
            pageScroll();
          }}>RESULT</button></div>
          <div className='col-md-4 col-sm-12'> <button className='btn btn-light mt-2 form-control col-md-4 col-sm-12' onClick={(e) => {
            e.preventDefault();
            pageScroll();
          }}>CHART</button></div>
        </div>

      </div>
      <div className="col-12 board text-center text-white">
        <p className="text-warning pt-2">{currentDateDisplay}</p>

        {/* Display data based on processed results */}
        {datagame?.map((todayData, index) => (
          <div key={index}>
            <p className="mb-0">{todayData?.gameName}</p>
            <p className="lh-1 blinking-text">{todayData?.result || 'wait'}</p>
          </div>
        ))}
      </div>
      <AdvertisementComponent type='odd' />
      <GameResult dayGameData={data} id='dayResult' />
    </div>



  )
}

export default App